/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { getGames } from '../api/games';
import { useForm } from '../hooks/useForm';
import { localeContext } from '../locales';

import FooterCareers from '../partials/FooterCareers';
import { Spinner, CaptchaForm, FormSubmitted } from '../components';

import utils from '../utils';
import { sendSupportData } from '../api/post';

const SupportForm = ({ texts, onSubmit }) => {
    const sanitizeInput = (val) => (val === '' ? null : val);

    const { handleSubmit, handleChange, data, errors } = useForm({
        validations: {
            name: {
                required: {
                    value: true,
                    message: 'Please enter a name.'
                }
            },
            game: {
                required: {
                    value: true,
                    message: 'Please choose a game.'
                }
            },
            os: {
                required: {
                    value: true,
                    message: 'Please choose OS.'
                }
            },
            version: {
                pattern: {
                    value: /^(0|[1-9]\d*)(\.(0|[0-9]\d*))?(\.(0|[0-9]\d*))?(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?$/,
                    message: 'Please enter a valid version.'
                }
            },
            email: {
                required: {
                    value: true,
                    message: 'Please enter email.'
                },
                pattern: {
                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: 'Please enter a valid email.'
                }
            },
            message: {
                required: {
                    value: true,
                    message: 'Please enter a message.'
                }
            }
        },
        onSubmit: () => onSubmit(data)
    });

    const [games, setGames] = useState([]);

    useEffect(() => {
        let mounted = true;

        getGames().then((data) => mounted && setGames(data));

        return () => {
            mounted = false;
        };
    }, []);

    const isInputValid = (key) => (errors[key] ? ' is-invalid' : '');

    return (
        <>
            <div className="title font-header">{texts.support_page_title}</div>
            <form onSubmit={handleSubmit}>
                <div className="d-flex flex-column">
                    <div className="d-flex flex-wrap form-row">
                        <div className="col-12 col-lg-6 form-input-left">
                            <label htmlFor="game" className="form-label">
                                {texts.contact_form_game}
                            </label>
                            <select
                                className={'form-select font-header' + isInputValid('game')}
                                value={data.game || ''}
                                onChange={handleChange('game', sanitizeInput)}
                            >
                                <option id="game" value="" hidden>
                                    {texts.contact_form_game_placeholder}
                                </option>
                                {games.map((game) => (
                                    <option key={game.name} value={game.name}>
                                        {game.name}
                                    </option>
                                ))}
                            </select>
                            {errors.game ? <div className="invalid-feedback">{errors.game}</div> : null}
                        </div>
                        <div className="col-12 col-lg-3 form-input-right form-col">
                            <label htmlFor="os" className="form-label">
                                {texts.contact_form_platform}
                            </label>
                            <select
                                className={'form-select' + isInputValid('os')}
                                value={data.os || ''}
                                onChange={handleChange('os', sanitizeInput)}
                            >
                                <option value="" hidden>
                                    {texts.contact_form_platform_placeholder}
                                </option>
                                <option value="android">ANDROID</option>
                                <option value="ios">IOS</option>
                            </select>
                            {errors.os ? <div className="invalid-feedback">{errors.os}</div> : null}
                        </div>
                        <div className="col-12 col-lg-3 form-input-right form-col">
                            <label htmlFor="version" className="form-label">
                                {texts.contact_form_version}
                                <span className="label-optional">{texts.form_optional}</span>
                            </label>
                            <input
                                type="text"
                                value={data.version || ''}
                                className={'form-control' + isInputValid('version')}
                                id="version"
                                onChange={handleChange('version', sanitizeInput)}
                                placeholder={texts.contact_form_version_placeholder}
                            />
                            {errors.version ? <div className="invalid-feedback">{errors.version}</div> : null}
                        </div>
                    </div>
                    <div className="d-flex flex-wrap">
                        <div className="col-12 col-lg-6 form-row form-input-left">
                            <label htmlFor="name" className="form-label">
                                {texts.contact_form_name}
                            </label>
                            <input
                                type="text"
                                value={data.name || ''}
                                className={'form-control' + isInputValid('name')}
                                id="name"
                                onChange={handleChange('name', sanitizeInput)}
                                placeholder={texts.contact_form_name_placeholder}
                            />
                            {errors.name ? <div className="invalid-feedback">{errors.name}</div> : null}
                        </div>
                        <div className="col-12 col-lg-6 form-row form-input-right">
                            <label htmlFor="email" className="form-label">
                                {texts.contact_form_email}
                            </label>
                            <input
                                type="text"
                                value={data.email || ''}
                                className={'form-control' + isInputValid('email')}
                                id="email"
                                onChange={handleChange('email', sanitizeInput)}
                                placeholder={texts.contact_form_email_placeholder}
                            />
                            {errors.email ? <div className="invalid-feedback">{errors.email}</div> : null}
                        </div>
                    </div>
                    <div className="form-row">
                        <label htmlFor="message" className="form-label">
                            {texts.contact_form_message}
                        </label>
                        <textarea
                            value={data.message || ''}
                            className={'form-control' + isInputValid('message')}
                            id="message"
                            onChange={handleChange('message', sanitizeInput)}
                            placeholder={texts.contact_form_message_placeholder}
                        />
                        {errors.message ? <div className="invalid-feedback">{errors.message}</div> : null}
                    </div>

                    <button type="submit" className="btn form-btn">
                        {texts.contact_form_send}
                    </button>
                </div>
            </form>
        </>
    );
};

const Support = (props) => {
    const texts = useContext(localeContext);

    const [data, setData] = useState({});

    const ref = useRef();

    const [step, setStep] = useState('fill'); // fill captcha sending ok;

    const onFormReady = (data) => {
        setData(data);
        setStep('captcha');
    };

    const onVerify = (token) => {
        setStep('sending');

        sendSupportData(data, token)
            .then((res) => {
                setStep('ok');
            })
            .catch((err) => {
                utils.hint('error');
                setStep('fill');
            });
    };

    const stepComponentMap = {
        fill: <SupportForm texts={texts} onSubmit={onFormReady} />,
        captcha: <CaptchaForm texts={texts} onSubmit={onVerify} />,
        sending: <Spinner />,
        ok: <FormSubmitted texts={texts} />
    };

    useLayoutEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        });
    }, []);

    useLayoutEffect(() => {
        if (step !== 'fill' && ref && ref.current) ref.current.scrollIntoView(false);
    }, [step]);

    return (
        <div className="support-page">
            <div className="support-header page-header d-flex flex-column">
                <div className="mx-auto d-flex flex-column">
                    <div className="title font-header text-center mx-auto">{texts.support_title}</div>
                    <div className="subtitle">{texts.support_subtitle}</div>
                </div>
            </div>
            <div className="form-body">
                <div className="form-container" ref={ref}>
                    {stepComponentMap[step]}
                </div>
            </div>
            <FooterCareers />
        </div>
    );
};

export default Support;
